@font-face {
  font-family: 'Sul Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Sul Sans'), local('SulSans'),
       url('./assets/fonts/SulSans-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
}

body {
  font-family: 'Sul Sans', sans-serif;
  color: #3E3E3E;
}

::-webkit-scrollbar {
  display: none;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}
